<template>
  <div class="home">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="banner swiper-slide">
          <img class="bannerBg" src="https://image.ceekee.com/officialwebsite/banner.png" />
          <img class="fontImg" src="https://image.ceekee.com/officialwebsite/wenan.png" />
          <div class="linkImg" @click="scrollIntoSignView">
            <img src="https://image.ceekee.com/officialwebsite/sign_btn.png" />
          </div>
          <div class="otherImg">
            <p>
              <img src="https://image.ceekee.com/pc/tripwise/20200117_2.png" />
              <img src="https://image.ceekee.com/pc/tripwise/20200117_1.png" />
              <img src="https://image.ceekee.com/pc/tripwise/20200117_3.png" />
            </p>
          </div>
        </div>
        <div class="banner swiper-slide">
          <img class="bannerBg" src="https://image.ceekee.com/pc/tripwise/20210106_1.png" />
          <img class="fontImg" src="https://image.ceekee.com/pc/tripwise/20210115_11.png" />
          <router-link class="linkImg" :to="this.$config.appointment_url">
            <img src="https://image.ceekee.com/pc/tripwise/20210106_2.png" />
          </router-link>
          <div class="otherImg">
            <p>
              <img src="https://image.ceekee.com/pc/tripwise/20200117_2.png" />
              <img src="https://image.ceekee.com/pc/tripwise/20200117_1.png" />
              <img src="https://image.ceekee.com/pc/tripwise/20200117_3.png" />
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>

    <div class="bannerPhone">
      现在咨询（180-5110-2396）
      <span>免费赠送 企业差旅管理执行方案一份</span>
      <router-link :to="this.$config.appointment_url">立即获取</router-link>
    </div>

    <div class="sign-form-item" id="sign-form-id">
      <div class="sign-form">
        <div class="form-rotation-chart">
          <div class="swiper-container" style="height: 100%">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="https://image.ceekee.com/officialwebsite/sign_rotation_chart_company.png" />
              </div>
              <div class="swiper-slide">
                <img src="https://image.ceekee.com/officialwebsite/sign_rotation_chart_person.png" />
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="form-item">
          <p class="form-title">免费注册领取</p>
          <div class="form-body">
            <el-form
              ref="ruleFormRef"
              label-position="top"
              label-width="100px"
              :model="formLabelAlign"
              style="max-width: 60%"
              :rules="rules"
            >
              <el-form-item label="联系方式" prop="phone">
                <el-input v-model="formLabelAlign.phone" placeholder="请输入您的号码" />
              </el-form-item>
              <el-form-item label="验证码" prop="smsCode">
                <AliyunCaptcha ref="AliyunCaptcha" @BizResult="getBizResult"></AliyunCaptcha>
                <el-row class="w-full">
                  <el-col :span="18">
                    <el-input v-model="formLabelAlign.smsCode" placeholder="请输入验证码" />
                  </el-col>
                  <el-col :span="6">
                    <el-button
                      v-if="!count"
                      type="text"
                      size="small"
                      link
                      @click="sendSms"
                      :disabled="!formLabelAlign.phone"
                      style="margin-left: 5px"
                    >
                      发送验证码
                    </el-button>
                    <div class="code-count" v-else>{{ count }}s</div>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="企业名称">
                <el-input v-model="formLabelAlign.companyName" placeholder="请输入企业名称" />
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="formLabelAlign.email" placeholder="请输入您的邮箱" />
              </el-form-item>
            </el-form>
            <div class="footer">
              <el-button type="primary" style="color: #ffffff" class="submit-btn" @click="submitForm('ruleFormRef')"
                >立即领取
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DialogNotify :isShow="notifyVisible" @closed="notifyVisible = false"></DialogNotify>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import DialogNotify from "../components/DialogNotify.vue";
import AliyunCaptcha from "../components/AliyunCaptcha.vue";

export default {
  name: "Sign",
  components: {
    DialogNotify,
    AliyunCaptcha,
  },
  data() {
    return {
      formLabelAlign: {
        name: "",
        phone: "",
        imgCode: "",
        smsCode: "",
        companyName: "",
        email: "",
      },
      plantId: "1039",
      notifyVisible: false,
      rules: {
        name: [{ required: false, message: "请输入您的姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入您的号码", trigger: "blur" },
          {
            pattern: /(^((\+86)|(86))?(1[3-9])\d{9}$)|(^(0\d{2,3})-?(\d{7,8})$)/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
      timer: null,
      count: 0,
      captchaVerifyParam: null,
    };
  },
  methods: {
    submitForm(formName) {
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          self.confirmSubmit();
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //发送验证码
    sendSms() {
      if (!this.checkInput()) return;
      this.$refs.AliyunCaptcha && this.$refs.AliyunCaptcha.handleCaptchaButton(this.formLabelAlign.phone);
    },
    getBizResult(res) {
      this.captchaVerifyParam = res.captchaVerifyParam;
      if (res.data) {
        this.getCode();
      }
    },

    checkInput() {
      if (!this.formLabelAlign.phone) {
        this.$message.error("请输入预约手机号");
        return false;
      }
      if (this.formLabelAlign.phone.length !== 11) {
        this.$message.error("请输入正确的手机号码");
        return false;
      }
      return true;
    },
    //验证码倒计时
    getCode() {
      const TIME_COUNT = 60;
      this.count = TIME_COUNT;
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--;
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    confirmSubmit() {
      if (!this.checkInput()) return;
      if (!this.formLabelAlign.smsCode) {
        this.$message.error("请输入短信验证码");
        return;
      }
      this.$api
        .experience({
          PlatId: this.plantId,
          ContactsName: this.formLabelAlign.phone, //姓名隐藏传手机号
          ContactsPhone: this.formLabelAlign.phone,
          EnterpriseName: this.formLabelAlign.companyName,
          Email: this.formLabelAlign.email,
          SMSCode: this.formLabelAlign.smsCode,
          captchaVerifyParam: this.captchaVerifyParam,
          Version: "5.0.3",
        })
        .then((res) => {
          //申请成功
          if (res.Code === "0000") {
            this.dialogVisible = false;
            this.resetForm("ruleFormRef");
            sessionStorage.setItem("hasSigned", "1");
            this.notifyVisible = true;
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch(() => {
          this.$message.error(res.Msg);
        });
    },
    scrollIntoSignView() {
      let top = document.getElementById("sign-form-id").offsetTop - 100;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    initSwiper() {
      setTimeout(() => {
        let mySwiper = new Swiper(".swiper-container", {
          slidesPerView: 1,
          // 改变拖动时鼠标指针形状
          grabCursor: true,
          // 设置轮播循环方式
          loop: true,
          // 自动播放间隔时间
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          // 分页器
          pagination: {
            el: ".swiper-pagination",
            bulletClass: "my-bullet",
            bulletActiveClass: "my-bullet-active",
            clickable: true,
          },
          // 轮播效果
          effect: "slide",
        });
      }, 100);
    },
  },
  mounted() {
    this.$store.commit("setSignIsShow", false);
    this.initSwiper();
    this.scrollIntoSignView();
  },
  beforeDestroy() {
    this.$store.commit("setSignIsShow", true);
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="less">
/deep/.my-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  opacity: 1;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
}

/deep/.my-bullet-active {
  width: 36px;
  height: 10px;
  background: #ffffff;
  border-radius: 5px;
  opacity: 1;
}

.banner {
  width: 100%;
  overflow: hidden;
  position: relative;

  .bannerBg {
    width: 100%;
  }

  .fontImg {
    position: absolute;
    width: 44%;
    top: 20%;
    left: 12.5%;
  }

  .linkImg {
    position: absolute;
    width: 12%;
    top: 56%;
    left: 17.5%;

    img {
      width: 100%;
    }
  }

  .otherImg {
    position: absolute;
    bottom: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    left: 10%;
    width: 80%;
    padding: 0px 0;

    p {
      width: 84%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      img {
        width: 26%;
      }
    }
  }
}

.bannerPhone {
  height: 70px;
  background: #060922;
  width: 100%;
  text-align: center;
  line-height: 70px;
  font-size: 18px;
  color: #fff;

  span {
    color: #0091ff;
  }

  a {
    background: #0091ff;
    border-radius: 4px;
    color: #fff;
    padding: 5px 30px;
    font-size: 16px;
    margin-left: 40px;
  }
}

.sign-form-item {
  width: 62.5vw;
  margin: 5.21vw auto;

  .sign-form {
    width: 62.5vw;
    min-height: 30.42vw;
    display: flex;
    border-radius: 16px;
    .w-full {
      width: 100%;
    }
    .h-full {
      height: 100%;
    }
    .img-code {
      font-size: 14px;
      line-height: 1.87vw;
      color: #0b7afe;
      cursor: pointer;
      width: 3.74vw;
      text-align: center;
      margin-left: 5px;
    }
    .code-count {
      text-align: center;
      color: #606266;
      font-size: 0.84vw;
      height: 1.875vw;
      line-height: 1.875vw;
    }

    .form-rotation-chart {
      width: 25.52vw;
      border-radius: 16px 0 0 16px;
      overflow: hidden;
      //height: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      .swiper-pagination {
        text-align: left;
        margin-left: 1.875vw;
      }
    }

    .form-item {
      flex: 1;
      // height: 26.06vw;
      padding: 2.18vw 3.64vw;
      display: flex;
      flex-direction: column;
      //overflow-y: hidden;
      box-shadow: 0 0 20px 0 rgba(71, 76, 111, 0.2);

      .form-title {
        height: 2.083vw;
        line-height: 2.083vw;
        font-size: 1.46vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }

      .form-body {
        margin-top: 3.33vh;

        /deep/.el-form {
          .el-form-item {
            margin-bottom: 1.125vw;
          }

          .el-form-item__label {
            height: 1.15vw;
            line-height: 1.15vw !important;
            padding: 0;
            font-size: 0.84vw;
            margin-bottom: 0.52vw;
          }

          .el-form-item__content {
            line-height: unset;
          }

          .el-input {
            height: 1.875vw;
            //display: block;

            .el-input__inner {
              height: 100%;
              line-height: 100%;
            }
          }
        }

        .footer {
          padding-top: 0.625vw;

          .submit-btn {
            background-color: #1022a2;
            border-radius: 4px;
            color: #ffffff;
            border: none;
            padding: 0.57vw 1.46vw;
          }
        }
      }
    }
  }
}
</style>
